<template>
  <div class="main">
    <div v-loading="loading" class="main-container">
      <div class="pt-wrapper">
        <!-- 搜索 -->
        <div class="hd-search">
          <table-search
            :model="searchParams"
            label-width="80px"
            :isClear="true"
            :flex="'6'"
            @search="searchFn"
          >
            <search-item default label="">
              <el-input
                size="small"
                v-model="searchParams.agencyName"
                placeholder="请输入机构名称"
                clearable
              >
              </el-input>
            </search-item>
            <search-item default label="">
              <el-select
                v-model="searchParams.grabType"
                placeholder="监测主体"
                clearable
              >
                <el-option
                  v-for="item in grabTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </search-item>

            <search-item>
              <el-select
                v-model="searchParams.status"
                placeholder="采集状态"
                clearable
              >
                <el-option
                  v-for="item in statuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </search-item>
          </table-search>
        </div>
        <div class="bd-content" style="margin-top: 16px">
          <!-- 表格 -->
          <pageTable
            ref="table"
            :columns="columnList"
            :params="searchParams"
            :api-function="this.$api.dataAcquisition.queryTaskList"
          >
            <template slot="grabType" slot-scope="scope">
              <div class="data-operate">
                <span v-if="scope.row.grabType === 0">网站</span>
                <span v-if="scope.row.grabType === 1">公众号</span>
              </div>
            </template>
            <template slot="status" slot-scope="scope">
              <div class="data-operate">
                <span v-if="scope.row.status === 0">待采集</span>
                <span v-if="scope.row.status === 1">采集中</span>
                <span v-if="scope.row.status === 2">已完成</span>
                <span v-if="scope.row.status === 3">已拒绝</span>
                <span v-if="scope.row.status === 4">已撤回</span>
              </div>
            </template>
            <template slot="createTime" slot-scope="scope">
              {{ scope.row.createTime | formatDate }}
            </template>
            <template slot="options" slot-scope="scope">
              <div class="data-operate">
                <span class="op-blue" @click="viewTasks(scope.row, 'view')">
                  查看
                </span>
                <span
                  v-show="scope.row.status === 0 || scope.row.status === 1"
                  class="op-yellow"
                  @click="reject(scope.row.id)"
                >
                  拒绝
                </span>
                <span
                  v-show="
                    scope.row.status === 0 && scope.row.configCompleted === 0
                  "
                  class="op-blue"
                  @click="collectOrder(scope.row, 'acquisition')"
                >
                  选择
                </span>
                <span
                  v-show="
                    scope.row.status === 0 && scope.row.configCompleted === 1
                  "
                  class="op-blue"
                  @click="collectOrder(scope.row, 'acquisition')"
                >
                  修改
                </span>
                <!-- configCompleted 1是配置完了0是没配置全 -->
                <span
                  v-show="
                    scope.row.status === 0 && scope.row.configCompleted === 1
                  "
                  class="op-green"
                  @click="crawlingAll(scope.row)"
                >
                  采集
                </span>
              </div>
            </template>
          </pageTable>
        </div>
      </div>
    </div>
    <el-dialog
      :title="hide === 'acquisition' ? '采集任务' : '查看采集任务'"
      :visible.sync="viewDialogVisible"
      width="70%"
      :close-on-click-modal="false"
      :modal-append-to-body="false" 
    >
      <div class="view-dialog">
        <div v-if="task.status === 3">
          <div class="form-lable">查看失败原因</div>
          <div class="view-border">{{ task.remarks ? task.remarks : '' }}</div>
        </div>
        <div class="form-lable">
          <div class="form-lable">基本信息</div>
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item label="任务id">
              {{ task.id ? task.id : '' }}
            </el-descriptions-item>
            <el-descriptions-item label="任务名称">
              {{ task.taskName ? task.taskName : '' }}
            </el-descriptions-item>
            <el-descriptions-item label="机构名称">
              {{ task.agencyName ? task.agencyName : '' }}
            </el-descriptions-item>
            <el-descriptions-item label="监测主体">
              {{ task.grabType ? task.grabType : '' | grabTypeFilters }}
            </el-descriptions-item>
            <el-descriptions-item
              :label="task.grabType === 1 ? '公众号' : '网站链接'"
            >
              {{ task.agencyHomeUrl ? task.agencyHomeUrl : '' }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="form-lable">
          <div class="form-lable">
            采集范围
            <span>
              <el-button
                v-if="hide === 'acquisition'"
                type="primary"
                style="margin-left: 30px; padding: 7px"
                @click="batchSelection()"
                >批量选择</el-button
              >
            </span>
          </div>
          <el-table
            border
            :data="task.channelList"
            style="width: 100%"
            ref="dataTable"
            @selection-change="handleSelectionChange"
            @cell-dblclick="copyText"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="selectableHide"
            >
            </el-table-column>
            <el-table-column type="index" label="序号"> </el-table-column>
            <el-table-column prop="status" label="采集结果">
              <template slot-scope="scope">
                {{ scope.row.status | statusFilters }}
              </template>
            </el-table-column>
            <el-table-column prop="channelName" label="模块名称">
            </el-table-column>
            <el-table-column prop="channelUrl" label="网页地址" width="450">
            </el-table-column>
            <el-table-column
              prop="modelId"
              v-if="task.status != 0"
              label="模版ID"
            >
            </el-table-column>
            <el-table-column
              prop="modelId"
              label="选择模版"
              v-if="hide === 'acquisition'"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="selectTemplate(scope.row)">
                  {{ scope.row.modelId ? scope.row.modelId : '选择模板' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog
        width="80%"
        title="选择模板"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
        :modal-append-to-body="false" 
      >
        <div class="view-dialog1">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <div>
              <el-form-item label="模版ID:">
                <el-input
                  v-model="formInline.id"
                  placeholder="模版ID"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="queryButton">查询</el-button>
                <el-button type="primary" @click="clearButton">重置</el-button>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button
                type="info"
                @click="pushTmplate"
                style="margin-right: 20px"
                >新增模板</el-button
              >
              <el-button type="primary" @click="saveTemplate">确定</el-button>
            </el-form-item>
          </el-form>
          <el-table
            border
            :data="list"
            style="width: 100%"
            @select-all="selectAll"
            @select="selectData"
            ref="multipleTable"
          >
            >
            <el-table-column
              type="selection"
              align="center"
              label="选择"
              width="55"
            ></el-table-column>
            <el-table-column prop="id" label="模型ID"></el-table-column>
            <el-table-column
              prop="selectType"
              :show-overflow-tooltip="true"
              label="选择器类型"
            ></el-table-column>
            <el-table-column prop="pageType" label="分页方式">
              <template slot-scope="scope">
                <span v-if="scope.row.pageType === 1">普通加载</span>
                <span v-if="scope.row.pageType === 2">动态加载</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="selectUrlList"
              :show-overflow-tooltip="true"
              label="列表页"
            ></el-table-column>
            <el-table-column
              prop="selectPage"
              :show-overflow-tooltip="true"
              label="开始分页"
            ></el-table-column>
            <el-table-column
              prop="selectPageStop"
              :show-overflow-tooltip="true"
              label="结束分页"
            ></el-table-column>
            <el-table-column
              prop="selectStart"
              :show-overflow-tooltip="true"
              label="翻页url开始拼接数"
            ></el-table-column>
            <el-table-column
              prop="selectTotalPage"
              :show-overflow-tooltip="true"
              label="列表页总页数"
            ></el-table-column>
            <el-table-column
              prop="selectDatePosted"
              :show-overflow-tooltip="true"
              label="列表页发布"
            ></el-table-column>
            <el-table-column
              prop="selectTitle"
              :show-overflow-tooltip="true"
              label="标题"
            ></el-table-column>
            <el-table-column
              prop="selectText"
              label="文章内容"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="selectPublishTime"
              :show-overflow-tooltip="true"
              label="发布时间"
            ></el-table-column>
            <el-table-column
              prop="selectArticleSource"
              :show-overflow-tooltip="true"
              label="文章来源"
            ></el-table-column>
            <el-table-column
              prop="remarks"
              :show-overflow-tooltip="true"
              label="备注"
            ></el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 30]"
              layout="->,total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  // 数据采集任务-运营人员下单
  name: 'dataCollector',
  data() {
    return {
      loading: false,
      // 我的任务列表
      columnList: [
        {
          label: '序号',
          type: 'index',
          width: 50,
        },
        {
          label: '任务名称',
          prop: 'taskName',
        },
        {
          label: '机构名称',
          prop: 'agencyName',
        },
        {
          label: '监测主体',
          show: 'template',
          prop: 'grabType',
        },
        {
          label: '网址/公众号',
          prop: 'agencyHomeUrl',
        },
        {
          label: '采集状态',
          show: 'template',
          prop: 'status',
        },
        {
          label: '任务创建时间',
          show: 'template',
          prop: 'createTime',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: window.innerWidth >= 1920 ? '160' : '200',
        },
      ],
      searchParams: {
        agencyName: '', //机构名称
        grabType: '', //监测主体
        // orderType: '', //订单类型
        status: null, //采集状态
      },
      grabTypes: [
        { label: '网站', value: 0 },
        { label: '公众号', value: 1 },
      ],
      orderTypes: [
        { label: '正式订单', value: '2000' },
        { label: '试用订单', value: '1000' },
      ],
      operationList: [],
      statuses: [
        { label: '待采集', value: 0 },
        { label: '采集中', value: 1 },
        { label: '已完成', value: 2 },
        { label: '已拒绝', value: 3 },
        { label: '已撤回', value: 4 },
      ],
      viewDialogVisible: false,
      innerVisible: false,
      //查看任务
      task: {},
      //模板
      list: [],
      formInline: {
        id: null,
      },
      total: 0,
      pageNum: 1,
      pageSize: 10,
      // 任务多选
      multipleSelection: [],
      //表格单选
      bill_id: null,
      //采集
      hide: 'acquisition',
      selectable: false,
      // 缓存 row
      cachingRow: {},
    };
  },
  filters: {
    grabTypeFilters: function (value) {
      let obj = {
        1: '网站',
        2: '公众号',
      };

      let name = obj[value] || '';
      return name;
    },
    statusFilters: function (value) {
      let obj = {
        0: '待执行',
        1: '抓取中',
        2: '已完成',
      };

      let name = obj[value] || '';
      return name;
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  methods: {
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData('text/plain', event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      document.addEventListener('copy', save); //添加一个copy事件
      document.execCommand('copy'); //执行copy方法
      this.$message({ message: '复制成功', type: 'success',duration:1000 }); //提示
    },
    searchFn() {
      const tableRef = this.$refs.table;
      tableRef.getPageDataOfAction('search');
    },
    // 去采集
    async collectOrder(row, name) {
      await this.viewTasks(row, name);
    },
    selectableHide() {
      if (this.hide === 'acquisition') {
        return true;
      } else {
        return false;
      }
    },
    //查看
    viewTasks(row, name) {
      this.cachingRow = row;
      this.hide = name;
      this.$api.dataAcquisition.queryById({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.task = res.data.task;
          this.viewDialogVisible = true;
          // if (this.hide === 'acquisition') {
          //   this.$nextTick(() => {
          //     this.$refs.dataTable.toggleAllSelection();
          //   });
          // }
        }
      });
    },
    // 查询
    queryButton() {
      this.queryModelPageList();
    },
    // 重置
    clearButton() {
      this.formInline.id = null;
      this.queryModelPageList();
    },
    //查看模板
    queryModelPageList(row) {
      let obj = {
        id: this.formInline.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$api.dataAcquisition.queryModelPageList(obj).then((res) => {
        if (res.code === 200) {
          this.list = res.data.list;
          this.total = res.data.total;
          if (row) {
            this.bill_id = row.modelId;
          }
          this.$nextTick(() => {
            this.list.forEach((item) => {
              if (item.id == this.bill_id) {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              }
            });
          });
        }
      });
    },
    // 拒绝
    reject(id) {
      const h = this.$createElement;
      this.$prompt(
        h('p', null, [
          h('i', { style: 'color: red' }, '* '),
          h('span', null, '拒绝原因'),
        ]),
        '拒绝采集',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^.+$/,
          inputType: 'textarea',
          inputErrorMessage: '请输入拒绝原因',
        }
      ).then(({ value }) => {
        this.$api.dataAcquisition
          .rejectById({
            id: id,
            remarks: value,
          })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('拒绝成功');
              this.$refs.table.getPageDataBeforeDel();
            }
          });
      });
    },
    //选择模板
    selectTemplate(row) {
      this.multipleSelection = [row];
      this.queryModelPageList(row);
      this.innerVisible = true;
    },
    // 批量选择任务
    batchSelection() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先勾选采集范围');
      } else {
        this.queryModelPageList();
        this.bill_id = null;
        this.innerVisible = true;
      }
    },
    //任务多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //模板选框-单
    selectData(selection, row) {
      this.bill_id = row.id;
      // 清除 所有勾选项
      this.$refs.multipleTable.clearSelection();
      // 当表格数据都没有被勾选的时候 就返回
      // 主要用于将当前勾选的表格状态清除
      if (selection.length == 0) return;
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    //模板选框-全
    selectAll() {
      this.$refs.multipleTable.clearSelection();
    },
    // 保存模板
    saveTemplate() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请选择采集范围模块',
          type: 'error',
        });
        return false;
      }
      if (!this.bill_id) {
        this.$message({
          message: '请选择模板',
          type: 'error',
        });
        return false;
      }
      const copyArr = [];
      this.multipleSelection.forEach((el) => {
        copyArr.push(el.id);
      });
      let selection = copyArr.join(',');
      let obj = {
        channelId: selection,
        modelId: this.bill_id,
      };
      this.$api.dataAcquisition.channelBindModel(obj).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '绑定成功',
            type: 'success',
          });
          this.innerVisible = false;
          this.viewTasks(this.cachingRow, 'acquisition');
          this.$forceUpdate();
          this.$refs.table.getPageDataBeforeDel();
        }
      });
    },
    crawlingAll(row) {
      this.$msg.confirm('确认执行吗？').then(() => {
        this.$api.dataAcquisition
          .crawlingAll({ taskId: row.id })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('执行成功');
              this.$refs.table.getPageDataBeforeDel();
            }
          });
      });
    },
    pushTmplate() {
      this.$router.push({
        path: '/templateManage',
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryModelPageList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.queryModelPageList();
    },
  },
  mounted() {},
  activated() {
    this.searchFn();
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  overflow: auto;
}
.form-lable {
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  margin: 5px 0;
  color: #333;
}
.view-border {
  height: 50px;
  line-height: 50px;
  width: 100%;
  border: 1px solid #f56c6c;
  border-radius: 4px;
  background-color: rgb(255, 250, 250);
  color: #f56c6c;
  padding-left: 20px;
}
.view-dialog {
  padding: 0 50px;
  max-height: 27rem;
  overflow-y: auto;
}
.view-dialog1 {
  max-height: 27rem;
  overflow-y: auto;
}
.demo-form-inline {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog .el-dialog__body {
  padding: 0 10px 15px 10px;
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  width: 120px;
}
.block {
  padding-top: 5px;
}
.el-table .el-table__cell.gutter {
  background: var(--tableHeadBg);
}
::v-deep .form-lable {
  line-height: 30px;
}
.form-buttom {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-table--small .el-table__cell {
  padding: 6px 0;
}
</style>
